<template>
  <div
      class="box"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-form ref="formRef" label-position="top" :model="form" :rules="rules">
      <el-form-item label="藏品头图" prop="head_img">
        <div class="upload-box">
          <el-upload
              ref="uploadHeadImage"
              :file-list="form.head_img"
              :action="uploadConfig.url"
              :headers="uploadConfig.header"
              :multiple="false"
              :auto-upload="false"
              :limit="1"
              :class="{ hide: uploadConfig.headImgHide }"
              list-type="picture-card"
              :on-change="handleHeadImgOnChange"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img :src="file.url" class="img">
              <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-delete" @click="handleHeadImgRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            </div>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="藏品全图" prop="img">
        <div class="upload-box">
          <el-upload
              ref="uploadImage"
              :file-list="form.img"
              :action="uploadConfig.url"
              :headers="uploadConfig.header"
              :multiple="false"
              :auto-upload="false"
              :limit="1"
              :class="{ hide: uploadConfig.imgHide }"
              list-type="picture-card"
              :on-change="handleImgOnChange"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img :src="file.url" class="img">
              <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-delete" @click="handleImgRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            </div>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="藏品名称" prop="name">
        <el-input v-model="form.name" clearable placeholder="请输入藏品名称" />
      </el-form-item>
      <el-form-item label="藏品价格" prop="money">
        <el-input v-model="form.money" clearable placeholder="请输入藏品价格" />
      </el-form-item>
      <el-form-item label="藏品分类" prop="cate_id">
        <el-select v-model="form.cate_id" placeholder="请选择藏品分类" style="width:100%;">
          <el-option v-for="(item, index) in cateList" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="藏品规格" prop="spec">
        <div style="display: flex;align-items: center;justify-content: flex-start;margin-bottom: 10px;">
          <el-input v-model="spec.title" placeholder="规格名称" style="margin-right:10px;" />
          <el-input v-model="spec.value" placeholder="规格值" style="margin-right:10px;" />
          <i class="el-icon-circle-plus-outline" style="width:80px;color: #009AB1;cursor:pointer" @click="pushSpec"></i>
        </div>
        <div v-for="(item, index) in form.spec" :key="index" style="display: flex;align-items: center;justify-content: flex-start;margin-bottom: 10px;">
          <el-input v-model="item.title" placeholder="规格名称" style="margin-right:10px;" />
          <el-input v-model="item.value" placeholder="规格值" style="margin-right:10px;" />
          <i class="el-icon-remove-outline" style="width:80px;color: #f00;cursor:pointer" @click="form.spec.splice(index, 1)"></i>
        </div>
      </el-form-item>
      <el-form-item label="藏品属性" prop="attribute">
        <div style="display: flex;align-items: center;justify-content: flex-start;margin-bottom: 10px;">
          <el-input v-model="attribute.title" placeholder="属性名称" style="margin-right:10px;" />
          <el-input v-model="attribute.value" placeholder="属性值" style="margin-right:10px;" />
          <i class="el-icon-circle-plus-outline" style="width:80px;color: #009AB1;cursor:pointer" @click="pushAttribute"></i>
        </div>
        <div v-for="(item, index) in form.attribute" :key="index" style="display: flex;align-items: center;justify-content: flex-start;margin-bottom: 10px;">
          <el-input v-model="item.title" placeholder="属性名称" style="margin-right:10px;" />
          <el-input v-model="item.value" placeholder="属性值" style="margin-right:10px;" />
          <i class="el-icon-remove-outline" style="width:80px;color: #f00;cursor:pointer" @click="form.attribute.splice(index, 1)"></i>
        </div>
      </el-form-item>
      <el-form-item label="藏品简介" prop="introduction">
        <el-input v-model="form.introduction" type="textarea" :autosize="{ minRows: 8, maxRows: 8}" show-word-limit :maxlength="300" placeholder="请输入藏品简介" />
      </el-form-item>
      <el-form-item label="作者简介" prop="author_introduction">
        <el-input v-model="form.author_introduction" type="textarea" :autosize="{ minRows: 8, maxRows: 8}" show-word-limit :maxlength="300" placeholder="请输入作者简介" />
      </el-form-item>
      <div class="button" @click="onSubmit">{{ form.id ? '提交更新' : '提交上链'}}</div>
      <div class="tip">
        *本次提交消耗{{ appInfo.topChainCoinExpend }}{{ appInfo.walletName }}
        <template v-if="memberInfo.balance < appInfo.topChainCoinExpend">，</template>
        <span v-if="memberInfo.balance < appInfo.topChainCoinExpend">
          您的 {{ appInfo.walletName }} 余额不足（当前余额：{{ memberInfo.balance }}），请下载APP充值 {{ appInfo.walletName }}
        </span>
        <span v-if="memberInfo.balance >= appInfo.topChainCoinExpend">
          您的 {{ appInfo.walletName }} 余额：{{ memberInfo.balance }}
        </span>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "nft-upload",
  data() {
    var isMoney = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入藏品价格'));
      } else {
        if (/(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(value)) {
          callback();
        }
        else {
          callback(new Error('请输入正确的藏品价格'));
        }
      }
    };
    return {
      loading: false,
      loadingText: '...',
      cateList: [],
      hideUpload: {
        head_img: false,
        img: false
      },
      form: {
        id: '',
        head_img: [],
        img: [],
        name: '',
        money: '',
        cate_id: '',
        spec: [],
        attribute: [],
        introduction: '',
        author_introduction: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入藏品名称', trigger: 'blur' },
        ],
        money: [
          { required: true, message: '请输入藏品价格', trigger: 'blur' },
          { validator: isMoney, trigger: 'blur' }
        ],
        cate_id: [
          { required: true, message: '请选择藏品分类', trigger: 'blur' }
        ],
        spec: [
          { required: true, message: '藏品规格至少添加一项', trigger: 'blur' }
        ],
        attribute: [
          { required: true, message: '藏品属性至少添加一项', trigger: 'blur' }
        ],
        introduction: [
          { required: true, message: '请输入藏品简介', trigger: 'blur' }
        ],
        author_introduction: [
          { required: true, message: '请输入藏品作者简介', trigger: 'blur' }
        ],
      },
      uploadConfig: {
        url: `${process.env.VUE_APP_URL}api/member_collection/uploadimg`,
        header: {
          token: ''
        },
        headImgHide: false,
        imgHide: false,
        isUploadHeadImg: true, // 默认需要上传头图
        isUploadImg: true, // 默认需要上传全图
        headImg: '',
        img: '',
      },
      spec: {
        title: '',
        value: '',
      },
      attribute: {
        title: '',
        value: '',
      }
    }
  },
  methods: {
    // 数据提交
    onSubmit() {
      if(this.memberInfo.balance < this.appInfo.topChainCoinExpend) {
        this.$message({
          message: "账户余额不足",
          type: "warning",
        });
      } else {
        // 检查图片
        if(this.form.head_img.length === 0) {
          this.$message({
            message: "请选择藏品头图",
            type: "warning",
          });
          return false
        }
        if(this.form.img.length === 0) {
          this.$message({
            message: "请选择藏品全图",
            type: "warning",
          });
          return false
        }
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            // 检查是否需要上传图
            if (this.uploadConfig.isUploadHeadImg) {
              // 上传头图
              this.loading = true
              this.loadingText = "上传头图..."
              this.$refs.uploadHeadImage.submit()
              return false;
            }
            if (this.uploadConfig.isUploadHeadImg === false && this.uploadConfig.isUploadImg) {
              // 上传全图
              this.loading = true
              this.loadingText = "上传全图..."
              this.$refs.uploadImage.submit()
              return false;
            }
            if (this.uploadConfig.isUploadHeadImg === false && this.uploadConfig.isUploadImg === false) {
              // 保存数据
              this.loading = true
              this.loadingText = "保存数据..."

              let data = JSON.parse(JSON.stringify(this.form))
              data.head_img = this.uploadConfig.headImg
              data.img = this.uploadConfig.img
              this.$api.nft_product.updateProduct(data).then(() => {
                setTimeout(() => {
                  this.loading = false
                }, 1500)
                setTimeout(() => {
                  if(this.form.id){
                    this.$message({
                      message: "藏品信息已成功更新",
                      type: "success",
                    });
                  } else {
                    this.$message({
                      message: "您的藏品已上链",
                      type: "success",
                    });
                  }
                  this.router('management')
                }, 1500)
              }).finally(() => {
                this.loading = false
              })
            }
          } else {
            return false;
          }
        });
      }
    },
    // 头图选择
    handleHeadImgOnChange(file) {
      if (file.status === "ready") {
        this.form.head_img.push(file)
        this.uploadConfig.isUploadHeadImg = true
        this.uploadConfig.headImgHide = true
      } else if (file.status === "success") {
        this.uploadConfig.headImg = file.response.data
        this.uploadConfig.isUploadHeadImg = false
        this.onSubmit()
      }
    },
    // 头图清空
    handleHeadImgRemove(file) {
      this.form.head_img = []
      this.uploadConfig.isUploadHeadImg = true
      this.uploadConfig.headImgHide = false
      this.$refs.uploadHeadImage.handleRemove(file)
    },
    // 全图选择
    handleImgOnChange(file) {
      if (file.status === "ready") {
        this.form.img.push(file)
        this.uploadConfig.isUploadImg = true
        this.uploadConfig.imgHide = true
      } else if (file.status === "success") {
        this.uploadConfig.img = file.response.data
        this.uploadConfig.isUploadImg = false
        this.onSubmit()
      }
    },
    // 全图清空
    handleImgRemove(file) {
      this.form.img = []
      this.uploadConfig.isUploadImg = true
      this.uploadConfig.imgHide = false
      this.$refs.uploadImage.handleRemove(file)
    },
    // 获取分类列表
    getCateList() {
      this.$api.nft_product.getCateList().then((res) => {
        this.cateList = res.data
      });
    },
    // 增加规格项
    pushSpec() {
      if(this.spec.title.length > 0 && this.spec.value.length > 0) {
        this.form.spec.push({ ...this.spec })
        this.spec.title = ''
        this.spec.value = ''
      } else {
        this.$message({
          message: "请输入规格名称和规格值",
          type: "warning",
        });
      }
    },
    // 增加属性
    pushAttribute() {
      if(this.attribute.title.length > 0 && this.attribute.value.length > 0) {
        this.form.attribute.push({ ...this.attribute })
        this.attribute.title = ''
        this.attribute.value = ''
      } else {
        this.$message({
          message: "请输入属性名称和属性值",
          type: "warning",
        });
      }
    },
    // 跳转页面
    router(url, query) {
      this.$router.push({
        path: url,
        query: query
      });
    },
  },
  created() {
    this.uploadConfig.header.token = this.vuex_userinfo.token
    this.getCateList()
    if(this.$route.query.id) {
      this.$api.nft_product.getProductInfo({ id: this.$route.query.id }).then((res) => {
        if (res.data) {
          // 数据赋值
          const info = res.data
          Object.assign(this.form, {
            id: info.id,
            head_img: [{
              name: info.head_img,
              url: info.head_img,
            }],
            img: [{
              name: info.img,
              url: info.img,
            }],
            name: info.name,
            money: info.money,
            cate_id: info.cate_id,
            spec: info.spec,
            attribute: info.attribute,
            introduction: info.introduction,
            author_introduction: info.author_introduction,
          })
          this.uploadConfig.headImg = info.head_img
          this.uploadConfig.img = info.img
          // 设置不需要上传图片了
          this.uploadConfig.isUploadHeadImg = false
          this.uploadConfig.isUploadImg = false
          this.uploadConfig.headImgHide = true
          this.uploadConfig.imgHide = true
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  text-align: left;
  width:900px;
  margin: auto;
}
.button {
  background:url('../../assets/nft/button.png') no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color:#ffffff;
  width:300px;
  height:50px;
  line-height: 50px;
  margin-top:50px;
  margin-bottom: 20px;
}
.tip {
  font-size:14px;
  color:#999999;
  margin-bottom:100px;
  span {
    color:#009AB1;
  }
}
/deep/.el-form-item__label {
  color:#ffffff;
}
/deep/.el-input__inner, /deep/.el-textarea__inner {
  background: transparent;
  border-color: #009AB1;
  color:#009AB1;
}
/deep/.el-select:hover .el-input__inner {
  border-color: #009AB1;
}
/deep/.el-select .el-input .el-select__caret {
  color:#009AB1;
}
/deep/.el-input__count {
  color:#ffffff;
  background: transparent;
}
.upload-box {
  /deep/.el-upload {
    background: rgba(0,154,177,0.1);
    border: 1px dashed #009AB1;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width:500px;
    height:200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-icon-plus {
    font-size: 28px;
    color: #009AB1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .el-icon-plus::after {
    content: "选择图片";
    color:#ffffff;
    font-size:16px;
    margin-top:10px;
  }
  /deep/.el-upload-list__item {
    width:500px;
    height:200px;
    background: rgba(0,154,177,0.1);
    border: 1px dashed #009AB1;
    border-radius: 6px;
    img {
      width:100%;
      height:200px;
    }
  }
}
.upload-box ::v-deep .hide .el-upload--picture-card {
  display: none;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: #009AB1;
  color:#ffffff;
}
</style>
